import React, { useRef, useState } from 'react';
import uuid from 'react-uuid';
import FormEdit from './FormEdit';
import FormCreate from './FormCreate';
import { getDefaultValuesByType } from '../../lib/utils/surveys';

const Form = (props) => {
  const { isEdit, setInput } = props;
  const [surveyName, setSurveyName] = useState(undefined);
  const [status, setStatus] = useState(false);
  const [random, setRandom] = useState(false);
  const [recordQuestions, setRecordQuestions] = useState(undefined);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [titleToDelete, setTitleToDelete] = useState('');
  const [jumpWarning, setJumpWarning] = useState(false);
  const [jumpData, setJumpData] = useState(null);
  const [idToDelete, setIdToDelete] = useState(undefined);
  const touched = useRef();

  const addTouched = (field) => {
    touched.current = {
      ...touched.current,
      [field]: true,
    };
  };

  const changeQuestionType = (id, type) => {
    const defaultValues = getDefaultValuesByType(type);
    const modified = {
      id,
      type,
      ...defaultValues,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  const changeQuestionTitle = (id, title) => {
    const target = recordQuestions?.find(item => item.id === id);
    const modified = {
      ...target,
      title,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  const handleClickDeleteQuestion = (id) => {
    const find = recordQuestions?.find(item => item.id === id);
    setIdToDelete(id);
    setTitleToDelete(find?.title);
    setConfirmDialog(true);
  };

  const deleteQuestion = (id) => {
    const filter = recordQuestions?.filter(item => item.id !== id);

    setRecordQuestions(filter);
  };

  const createQuestion = () => {
    const filter = recordQuestions ? [...recordQuestions] : [];
    const defaultValues = {
      type: 'radio-checkbox',
      id: uuid(),
      answersAvailable: [],
      title: '',
      options: {
        isMultiple: false,
      },
    };
    filter.push({ ...defaultValues });
    setRecordQuestions(filter);
  };

  const changeQuestionAnswerValue = (questionId, index, value) => {
    const target = recordQuestions?.find(item => item.id === questionId);
    const { answersAvailable } = target;
    const find = answersAvailable?.find(item => item.id === index);
    const parsedFind = {
      ...find,
      title: value,
    };
    /* eslint-disable-next-line */
    const parsedAnswers = answersAvailable.map(item => item.id === parsedFind.id ? parsedFind : item);
    const parsedTarget = {
      ...target,
      answersAvailable: parsedAnswers,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === parsedTarget.id ? parsedTarget : item);

    setRecordQuestions(result);
    addTouched(`q_${questionId}_a_${index}`);
  };

  const removeQuestionAnswer = (questionId, index) => {
    const target = recordQuestions?.find(item => item.id === questionId);
    const { answersAvailable } = target;
    const filter = answersAvailable?.filter(item => item.id !== index);
    const parsedTarget = {
      ...target,
      answersAvailable: filter,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === parsedTarget.id ? parsedTarget : item);

    setRecordQuestions(result);
  };

  const addQuestionAnswer = (questionId) => {
    const target = recordQuestions?.find(item => item.id === questionId);
    const answers = target?.answersAvailable ? [...target.answersAvailable] : [];
    answers.push({ id: uuid(), title: '' });
    const modifiedTarget = {
      ...target,
      answersAvailable: answers,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modifiedTarget.id ? modifiedTarget : item);

    setRecordQuestions(result);
  };

  const toggleStatus = () => {
    setStatus(prev => !prev);
  };

  const toggleRandom = () => {
    setRandom(prev => !prev);
  };

  if (isEdit) {
    return (
      <FormEdit
        touched={touched}
        addTouched={addTouched}
        setInput={setInput}
        recordQuestions={recordQuestions}
        setRecordQuestions={setRecordQuestions}
        changeQuestionType={changeQuestionType}
        changeQuestionTitle={changeQuestionTitle}
        handleClickDeleteQuestion={handleClickDeleteQuestion}
        deleteQuestion={deleteQuestion}
        createQuestion={createQuestion}
        changeQuestionAnswerValue={changeQuestionAnswerValue}
        removeQuestionAnswer={removeQuestionAnswer}
        addQuestionAnswer={addQuestionAnswer}
        status={status}
        setStatus={setStatus}
        toggleStatus={toggleStatus}
        random={random}
        setRandom={setRandom}
        toggleRandom={toggleRandom}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        titleToDelete={titleToDelete}
        idToDelete={idToDelete}
        jumpWarning={jumpWarning}
        setJumpWarning={setJumpWarning}
        jumpData={jumpData}
        setJumpData={setJumpData}
        surveyName={surveyName}
        setSurveyName={setSurveyName}
        {...props}
      />
    );
  }

  return (
    <FormCreate
      touched={touched}
      addTouched={addTouched}
      setInput={setInput}
      recordQuestions={recordQuestions}
      setRecordQuestions={setRecordQuestions}
      changeQuestionType={changeQuestionType}
      changeQuestionTitle={changeQuestionTitle}
      handleClickDeleteQuestion={handleClickDeleteQuestion}
      deleteQuestion={deleteQuestion}
      createQuestion={createQuestion}
      changeQuestionAnswerValue={changeQuestionAnswerValue}
      removeQuestionAnswer={removeQuestionAnswer}
      addQuestionAnswer={addQuestionAnswer}
      status={status}
      setStatus={setStatus}
      toggleStatus={toggleStatus}
      random={random}
      setRandom={setRandom}
      toggleRandom={toggleRandom}
      confirmDialog={confirmDialog}
      setConfirmDialog={setConfirmDialog}
      titleToDelete={titleToDelete}
      idToDelete={idToDelete}
      jumpWarning={jumpWarning}
      setJumpWarning={setJumpWarning}
      jumpData={jumpData}
      setJumpData={setJumpData}
      surveyName={surveyName}
      setSurveyName={setSurveyName}
      {...props}
    />
  );
};

export default Form;
