import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useFormState } from 'react-final-form';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import Question from './Question';

import surveyStyles from '../surveyStyles';
import { QUESTION_TYPES } from '../../../lib/utils/surveys';

const Questions = (props) => {
  const {
 recordQuestions, setRecordQuestions, setJumpWarning, setJumpData,
} = props;
  const { errors } = useFormState();
  const classes = surveyStyles();

  const reorder = (list, startIndex, endIndex) => {
    const zero = 0;
    const one = 1;
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, one);
    result.splice(endIndex, zero, removed);

    return result;
  };

  const checkJumps = (items) => {
    const parsedItems = items;
    let changed = false;
    const changes = [];
    items.forEach((item) => {
      if (item.type === QUESTION_TYPES.LIST && item?.options?.isMultiple === false) {
        const { answersAvailable } = item;
        answersAvailable.forEach((answer) => {
          let newAnswer = answer;
          const { jumpToQuestionId } = answer;

          if (jumpToQuestionId && jumpToQuestionId !== 'end') {
            const indexSource = items.findIndex(el => el.id === item.id);
            const indexDest = items.findIndex(el => el.id === jumpToQuestionId);

            if (indexSource > indexDest) {
              const answerIndex = answersAvailable.findIndex(el => el.id === answer.id);
              newAnswer = {
                ...answer,
                jumpToQuestionId: undefined,
              };
              parsedItems[indexSource].answersAvailable[answerIndex] = newAnswer;
              changes.push({ from: item.id, to: jumpToQuestionId });
              changed = true;
            }
          }
        });
      }
    });

    if (changed) {
      setJumpData(changes);
      setJumpWarning(true);
    }

    return parsedItems;
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const newItems = checkJumps(reorder(recordQuestions, source.index, destination.index));

    setRecordQuestions(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <div
            className={classes.questionsWrapper}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {errors?.questions && (
              <div className={classes.questionsErrorWrapper}>
                <ErrorIcon />
                <Typography color="error">{errors.questions}</Typography>
              </div>
            )}
            {recordQuestions?.map((item, index) => (
              <Draggable
                draggableId={`${item.id}`}
                index={index}
                key={item.id}
              >
                {(providedDraggable, snapshot) => (
                  <div
                    ref={providedDraggable.innerRef}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                    className={snapshot.isDragging ? classes.draggingListItem : ''}
                  >
                    <Question
                      {...item}
                      {...props}
                      id={item.id}
                      options={item.options}
                      index={index + 1}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Questions;
