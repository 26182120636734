import { makeStyles } from '@material-ui/core/styles';

const getColorByIndex = index => (index % 2 === 0 ? '#F2F2F2' : 'transparent');

const surveyStyles = makeStyles(() => ({
  questionsWrapper: {
    boxSizing: 'border-box',
    padding: '15px 0',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
  questionsErrorWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '0 10px',
    color: '#f44336',
  },
  questionContainer: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '24px 13% auto 24px',
    alignItems: 'center',
    gap: '0 15px',
    padding: '15px 15px',
    borderRadius: '4px',
    backgroundColor: props => getColorByIndex(props.index),
    transition: 'all 0.3s ease',

    '&:hover': {
      backgroundColor: 'rgba(63, 161, 234, 0.1)',
    },
  },
  questionListContainer: {
    display: 'grid',
    gridTemplateColumns: '85% auto',
    placeItems: 'center',
    gap: '0 15px',
  },
  questionListAccordion: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 'unset',
      width: '100%',
    },
  },
  questionListAnswersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px 0',
  },
  pointer: {
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '50%',
    transition: 'all 0.3s ease',

    '&:hover': {
      background: 'rgba(0, 0, 0, .15)',
    },
  },
  indexIndicator: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    backgroundColor: 'transparent',
    border: '1px ridge black',
    cursor: 'grab',
    borderRadius: '50%',
    transition: 'all 0.3s ease',

    '&:hover': {
      background: 'rgba(0, 0, 0, .15)',
    },
  },
  answersContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '0 10px',
  },
  answerColumnForIfJump: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
  },
  answerJumpContainer: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '24px 18% auto',
    alignItems: 'center',
    gap: '0 5px',
    padding: '0 10px',
  },
  draggingListItem: {
    background: '#3FA1EA',
    cursor: 'grabbing',
  },
  addQuestionContainer: {
    margin: '15px 0',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '0 10px',
    cursor: 'pointer',
    width: 'max-content',
  },
  warningList: {
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',

    '& ul': {
      margin: '15px 0',
      padding: '0 20px',

      '& li': {
        listStyle: 'none',
        margin: '15px 0',
      },
    },
  },
  horizontalAligned: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0 3px',
  },
}));

export default surveyStyles;
