import {
  maxLength,
  required,
} from 'react-admin';

import moment from 'moment';
import checkGTIN from './GTINCheckDigit';

import {
  PROMO_NAME_MAX_LENGTH, PROMO_TYPE_LENGTH, DESCRIPTION_MAX_LENGTH, LEGAL_POLICIES_MAX_LENGTH,
  COUPON_CODE_MAX_LENGTH, ERROR_TEXT, ONE, ACTIVE_STATUS, AUTO_PUBLISH_STATUS, RADIX_TEN,
  FIRST_ITEM, VALID_EAN13_LENGTH, ORDER_VALIDITY_LENGTH, EMPTY, PROMOTION_TYPES,
} from './constants';
import { convertHtmlToMarkdown, filterEans } from '../../lib/utils';

export const validateCoupon = (coupon) => {
  if ((coupon?.length && !checkGTIN(coupon))
  || (coupon?.length && coupon?.length !== VALID_EAN13_LENGTH)) {
    return 'Please introduce a valid EAN13 code';
  }
  return null;
};

export const validateRetailers = (retailers, currentStatus) => {
  if ((currentStatus === ACTIVE_STATUS || currentStatus === AUTO_PUBLISH_STATUS)
  && !retailers?.length) {
    return 'This field is mandatory';
  }
  return null;
};

export const validateOrderValidity = (orderValidity, currentStatus, translate) => {
  if ((currentStatus === ACTIVE_STATUS || currentStatus === AUTO_PUBLISH_STATUS)
  && !orderValidity) {
    return translate('app.required');
  }
  if (orderValidity && (orderValidity <= FIRST_ITEM || orderValidity > ORDER_VALIDITY_LENGTH)) {
    return translate('promotion.edit.orderValidityError');
  }
  return null;
};

export const validateOrderCancellation = (value, translate) => {
  if (value?.length && (value <= FIRST_ITEM || value > ORDER_VALIDITY_LENGTH)) {
    return translate('promotion.edit.orderValidityError');
  }
  return null;
};

const validatePastDate = (dateValue, currStatus, recordStartDate, prevStatus) => {
  let errors = '';
  if (!dateValue) return null;
  if (dateValue) {
    const todayParsed = moment().format('YYYY-MM-DD');

    if (recordStartDate && recordStartDate === dateValue && prevStatus === currStatus) {
      return null;
    }
    if (currStatus === ACTIVE_STATUS && moment(dateValue).isAfter(moment(todayParsed))) {
      errors = 'Start date must be lower or equal to current date';
    } else if (currStatus === AUTO_PUBLISH_STATUS
      && !moment(dateValue).isAfter(moment(todayParsed))) {
      errors = 'Start date must be in the future';
    } else if (dateValue < todayParsed && currStatus === AUTO_PUBLISH_STATUS) {
      errors = 'Start date must be greater than or equal to current date';
    }
  }
  return errors;
};

export const validateDateStartDate = (
  dateValue,
  endDate,
  currStatus,
  recordStartDate,
  prevStatus,
) => {
  if (dateValue) {
    const endTime = endDate ?? null;

    if (endDate && moment(dateValue).isAfter(moment(endTime))) {
      return 'Start date must be equal or before the end date';
    }
    return validatePastDate(dateValue, currStatus, recordStartDate, prevStatus);
  }
  if ((currStatus === AUTO_PUBLISH_STATUS || currStatus === ACTIVE_STATUS) && !dateValue) {
    return 'This field is mandatory';
  }
  return null;
};

export const validateDateEndDate = (dateValue, startDate) => {
  if (dateValue) {
    const startTime = moment(startDate).utc().format('YYYY-MM-DD');
    const endTime = moment(dateValue).utc().format('YYYY-MM-DD');
    const todayTime = moment().utc().format('YYYY-MM-DD');

    if (moment(todayTime).isAfter(endTime)) {
      return 'End date must be greater than current date';
    }

    if (moment(startTime).isAfter(moment(endTime))) {
      return 'End date must be equal or greater than the start date';
    }
  }
  return null;
};

export const validateSafetyStock = (safetyStock, initialStock, translate) => {
  if (safetyStock) {
    const intValue = parseInt(safetyStock, RADIX_TEN);
    if (intValue < FIRST_ITEM) {
      return translate('promotion.validation.stockPercentage');
    }
  } else if (initialStock) {
    if (safetyStock?.length === FIRST_ITEM || safetyStock === FIRST_ITEM) {
      return null;
    }
    return translate('promotion.validation.mustSafetyStock');
  }
  return null;
};

const legalTextValidation = (value) => {
  if (value) {
    const trimmedValue = convertHtmlToMarkdown(value)?.replace(/[!@#$%^&*=_\n]/g, '');
    if (trimmedValue.length > LEGAL_POLICIES_MAX_LENGTH) {
      return 'Must be 700 characters or less';
    }
  }
  return null;
};

const descriptionValidation = (value) => {
  if (value) {
    const trimmedValue = convertHtmlToMarkdown(value)?.replace(/[!@#$%^&*=_\n]/g, '');
    if (trimmedValue.length > DESCRIPTION_MAX_LENGTH) {
      return 'Must be 1000 characters or less';
    }
  }
  return null;
};

const validateInitialStock = (value) => {
  if (value) {
    const intValue = parseInt(value, RADIX_TEN);
    if (intValue < FIRST_ITEM) {
      return 'Initial stock cannot be less than 0';
    } if (value.length > RADIX_TEN) {
      return 'The maximum number of digits is 10';
    }
  }
  return null;
};

const validateColor = (value) => {
  const hexRegex = /^#[0-9A-F]{6}$/i;
  if (value && !hexRegex.test(value)) {
    return 'Please select valid color';
  }
  return null;
};

const validatePoints = (points, state) => {
  const pointsAreNull = points === null;
  const pointsAreUndefined = points === undefined;

  if ((pointsAreNull || pointsAreUndefined)
    && [ACTIVE_STATUS, AUTO_PUBLISH_STATUS].includes(state.status.key)) {
    return 'This field is mandatory';
  }

  return null;
};

const validateCodes = (codes, state) => {
  const codesAreZero = codes === EMPTY;
  const codesAreUndefined = codes === undefined;

  if (state.status && (codesAreZero || codesAreUndefined)
    && [ACTIVE_STATUS, AUTO_PUBLISH_STATUS].includes(state.status.key)) {
    return 'Must be greater than 0';
  }

  return null;
};

export const PromotionPointsValidation = {
  promotionName: [required(ERROR_TEXT), maxLength(PROMO_NAME_MAX_LENGTH)],
  promotionType: [maxLength(PROMO_TYPE_LENGTH)],
  promotionDescription: [descriptionValidation],
  promotionLegalText: [legalTextValidation],
  promotionCouponCode: [maxLength(COUPON_CODE_MAX_LENGTH)],
  promotionStartAt: [],
  promotionColor: [validateColor],
  promotionInitialStock: [validateInitialStock],
  promotionSafetyStock: [validateSafetyStock],
  promotionCodes: [validateCodes],
};

export const PromotionWithdrawalValidation = {
  promotionName: [required(ERROR_TEXT), maxLength(PROMO_NAME_MAX_LENGTH)],
  promotionType: [maxLength(PROMO_TYPE_LENGTH)],
  promotionDescription: [descriptionValidation],
  promotionLegalText: [legalTextValidation],
  promotionStartAt: [],
  promotionColor: [validateColor],
  promotionInitialStock: [validateInitialStock],
  promotionSafetyStock: [],
};

export const PromotionHomeDeliveryValidation = {
  promotionName: [required(ERROR_TEXT), maxLength(PROMO_NAME_MAX_LENGTH)],
  promotionType: [maxLength(PROMO_TYPE_LENGTH)],
  promotionDescription: [descriptionValidation],
  promotionLegalText: [legalTextValidation],
  promotionStartAt: [],
  promotionColor: [validateColor],
  promotionInitialStock: [validateInitialStock],
  promotionSafetyStock: [],
};

export const PromotionMandatory = {
  promotionName: [required(ERROR_TEXT), maxLength(PROMO_NAME_MAX_LENGTH)],
  promotionType: [maxLength(PROMO_TYPE_LENGTH)],
  promotionDescription: [required(ERROR_TEXT), descriptionValidation],
  promotionLegalText: [required(ERROR_TEXT), legalTextValidation],
  promotionCouponCode: [maxLength(COUPON_CODE_MAX_LENGTH)],
  promotionStartAt: [required(ERROR_TEXT)],
  promotionImageUpload: [required(ERROR_TEXT)],
  promotionValue: [required(ERROR_TEXT)],
  promotionColor: [validateColor],
  promotionInitialStock: [validateInitialStock],
  promotionSafetyStock: [required('If you don’t want to configure a safety stock, this value must be 0')],
  promotionPoints: [validatePoints],
  promotionCodes: [validateCodes],
};

export const PromotionWithdrawalMandatory = {
  promotionName: [required(ERROR_TEXT), maxLength(PROMO_NAME_MAX_LENGTH)],
  promotionType: [maxLength(PROMO_TYPE_LENGTH)],
  promotionDescription: [required(ERROR_TEXT), descriptionValidation],
  promotionLegalText: [required(ERROR_TEXT), legalTextValidation],
  promotionStartAt: [required(ERROR_TEXT)],
  promotionImageUpload: [required(ERROR_TEXT)],
  promotionValue: [required(ERROR_TEXT)],
  promotionColor: [validateColor],
  promotionInitialStock: [validateInitialStock],
  promotionSafetyStock: [required('If you don’t want to configure a safety stock, this value must be 0')],
  promotionPoints: [validatePoints],
};

export const PromotionHomeDeliveryMandatory = {
  promotionName: [required(ERROR_TEXT), maxLength(PROMO_NAME_MAX_LENGTH)],
  promotionDescription: [required(ERROR_TEXT), descriptionValidation],
  promotionLegalText: [required(ERROR_TEXT), legalTextValidation],
  promotionStartAt: [required(ERROR_TEXT)],
  promotionImageUpload: [required(ERROR_TEXT)],
  promotionInitialStock: [validateInitialStock],
  promotionSafetyStock: [required('If you don’t want to configure a safety stock, this value must be 0')],
  promotionPoints: [validatePoints],
};

const isHeadingStyleSelected = (data) => {
  let isEmpty = false;
  switch (data) {
    case '<h1><br></h1>':
    case '<h2><br></h2>':
    case '<h3><br></h3>':
    case '<hr />':
      isEmpty = true;
      break;
    default:
      isEmpty = false;
  }
  return isEmpty;
};

export const validatePromotionFields = (values) => {
  const errors = {};
  const {
    startDate, endDate, type, description, legalText, status, imageUrl,
  } = values;
  // Status Active validations
  if (status?.key !== ONE) {
    if (!startDate) {
      errors.startDate = ERROR_TEXT;
    }
    if (!type) {
      errors.type = ERROR_TEXT;
    }
    if (!description || (isHeadingStyleSelected(description))) {
      errors.description = ERROR_TEXT;
    }
    if (!legalText || (isHeadingStyleSelected(legalText))) {
      errors.legalText = ERROR_TEXT;
    }
    if (!imageUrl) {
      errors.imageUrl = ERROR_TEXT;
    }
  }
  // Start Date and End Date validations
  if (startDate && endDate) {
    const promoStartDate = new Date(startDate).getTime();
    const promoEndDate = new Date(endDate).getTime();
    if (promoStartDate > promoEndDate) {
      errors.startDate = 'Start Date must be less than or equal to end date';
    } else if (promoEndDate < promoStartDate) {
      errors.endDate = 'End Date must greater than or equal to start date';
    }
  }

  return errors;
};

export const validateButton = (data, values, eans) => {
  if (!data?.name || values?.name === null || values?.name?.length === EMPTY) {
    return true;
  }

  if (data?.eans?.length === EMPTY || eans === EMPTY) {
    return true;
  }

  if (data?.imageUrl === null || values?.imageUrl === null) {
    return true;
  }


  return false;
};

export const parseRetailerStocksForUpdate = (values) => {
  let output = null;

  if (values) {
    output = [];
    values.forEach((value) => {
      output.push({
        pickupPoint: value?.pickupPoint?.id,
        initialStock: value?.initialStock,
      });
    });
  }

  return output;
};

export const parseRetailersFromRetailerStocks = (values) => {
  let output = null;

  if (values) {
    output = [];
    values.forEach(value => output.push(value?.pickupPoint));
  }

  return output;
};

export const parsePromotionUpdateInputToValidate = (values) => {
  const updateParams = { data: { ...values } };

  const pickupPointStocks = parseRetailerStocksForUpdate(updateParams.data.pickupPointStocks);
  const promoTypeName = updateParams.data.promotionType.name;

  delete updateParams.data.survey;
  delete updateParams.data.couponCodesStats;
  delete updateParams.data.promotionType;
  delete updateParams.data.pickupPointStocks;

  const spanPara = /<p>(<strong>|<em>)<span class="ql-cursor">.?<\/span>(<\/strong>|<\/em>)<\/p>/g;
  const descriptionChanged = updateParams.data.description?.replace(/<p><br><\/p>/g, '<p>\u2800</p>')?.replace(spanPara, '<p>\u2800</p>');
  const legalTextChanged = updateParams.data.legalText?.replace(/<p><br><\/p>/g, '<p>\u2800</p>')?.replace(spanPara, '<p>\u2800</p>');
  let initialStock;
  let safetyStock;
  let daysUntilOrderCancellation;
  let daysForOrdersValidity;
  let retailers;

  if (promoTypeName === PROMOTION_TYPES.REDEMPTION_POINT) {
    initialStock = ONE;

    if (updateParams.data.promotionStock?.safetyStock
      || updateParams.data.promotionStock?.safetyStock === FIRST_ITEM) {
      safetyStock = parseInt(updateParams.data.promotionStock?.safetyStock, RADIX_TEN);
    }

    retailers = parseRetailersFromRetailerStocks(pickupPointStocks);
    daysForOrdersValidity = updateParams.data.daysForOrdersValidity
      && parseInt(updateParams.data.daysForOrdersValidity, RADIX_TEN);
  } else if (promoTypeName === PROMOTION_TYPES.WITH_POINTS
    || promoTypeName === PROMOTION_TYPES.WITHOUT_POINTS) {
    if (updateParams.data.promotionStock?.initialStock) {
      initialStock = parseInt(updateParams.data.promotionStock?.initialStock, RADIX_TEN);
    }
    if (initialStock && (updateParams.data.promotionStock?.safetyStock
      || updateParams.data.promotionStock?.safetyStock === FIRST_ITEM)) {
      safetyStock = parseInt(updateParams.data.promotionStock?.safetyStock, RADIX_TEN);
    }

    daysUntilOrderCancellation = updateParams.data.daysUntilOrderCancellation
      && parseInt(updateParams.data.daysUntilOrderCancellation, RADIX_TEN);

    retailers = updateParams
      .data.retailers?.map(
        retailerItem => retailerItem.id || retailerItem,
      );

    daysForOrdersValidity = updateParams.data.daysForOrdersValidity
      && parseInt(updateParams.data.daysForOrdersValidity, RADIX_TEN);
  } else {
    if (updateParams.data.promotionStock?.initialStock) {
      initialStock = parseInt(updateParams.data.promotionStock?.initialStock, RADIX_TEN);
    }
    if (initialStock && (updateParams.data.promotionStock?.safetyStock
      || updateParams.data.promotionStock?.safetyStock === FIRST_ITEM)) {
      safetyStock = parseInt(updateParams.data.promotionStock?.safetyStock, RADIX_TEN);
    }
  }

  const output = {
    ...updateParams.data,
    id: updateParams.data.uuid,
    uuid: undefined,
    daysForOrdersValidity,
    daysUntilOrderCancellation,
    statusKey: updateParams.data.status.key,
    status: undefined,
    eans: filterEans(updateParams.data.eans),
    points: parseInt(updateParams.data.points, RADIX_TEN),
    initialStock,
    safetyStock,
    promotionStock: undefined,
    description: descriptionChanged,
    legalText: legalTextChanged,
    key: undefined,
    __typename: undefined,
    retailersList: undefined,
    pickupPoints: retailers,
    pickupPointStocks,
  };

  return JSON.stringify(output);
};

export const parseValidateResponse = (response) => {
  let msg = 'Ha ocurrido un error al validar el formulario:';

  Object.entries(response).forEach(([key, value]) => {
    value.map((item) => {
      if (key === 'global') {
        msg = `${msg}\n - ${item.message}`;
      } else {
        msg = `${msg}\n - ${key}: ${item.message}`;
      }
      return true;
    });
  });

  return msg;
};
