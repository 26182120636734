import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';

const ConfirmDelete = (props) => {
  const {
    id,
    titleToDelete,
    confirmDialog,
    setConfirmDialog,
    handleSubmit,
  } = props;

  const handleClickConfirm = () => {
    handleSubmit(id);
    setConfirmDialog(false);
  };

  return (
    <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
      <DialogTitle>Confirm delete question</DialogTitle>
      <DialogContent>
        <Typography>
          You are trying to delete this question:
          <p><strong>{titleToDelete}</strong></p>
        </Typography>
        <Typography>This action can`t be undone. Are you sure?</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => setConfirmDialog(false)}>Cancel</Button>
        <Button color="primary" onClick={handleClickConfirm}>Confirm & delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDelete;
